/* global Office */
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import { useStorage } from "@vueuse/core";

import uk_amenities from "@/config/amenities_maps/uk_amenities_map.json";
import osm_bus_amenities from "@/config/amenities_maps/osm_bus_map.json";
import local_transport_amenities from "@/config/amenities_maps/local_transport_map.json";
import us_amenities from "@/config/amenities_maps/us_amenities_map.json";
import ev_charger from "@/config/amenities_maps/ev_charger_map.json";
import community_facilities from "@/config/amenities_maps/community_facilities_map.json";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "",
            redirect: {
                name: "portal",
            },
            meta: {
                auth: "any",
            },
        },
        {
            path: "/project/:id",
            name: "project",
            component: () => import("../views/SchemeflowProject.vue"),
            meta: {
                auth: "authenticated",
                checkTier: false,
            },
        },
        {
            path: "/edit/:id",
            name: "edit",
            component: () => import("../views/Reports/TransportStatement.vue"),
            props: {
                editable: true,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
                featureFlag: "legacyReportEditor",
            },
        },
        {
            path: "/toolbox/site_map/:id",
            name: "site_map",
            component: () => import("../views/Toolbox/SiteMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/satellite_map/:id",
            name: "satellite_map",
            component: () => import("../views/Toolbox/SatelliteMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/bus_services/:id",
            name: "bus_services",
            component: () => import("../views/Toolbox/BusServicesMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/bus_services_xml_table/:id",
            name: "bus_services_xml_table",
            component: () => import("../views/Toolbox/BusServicesMap.vue"),
            props: {
                insertBusServicesTableAsXML: true,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/highways_map/:id",
            name: "highways_map",
            component: () => import("../views/Toolbox/HighwaysMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/rail_services/:id",
            name: "rail_services",
            component: () => import("../views/Toolbox/RailServices.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/rail_services_xml_table/:id",
            name: "rail_services_xml_table",
            component: () => import("../views/Toolbox/RailServices.vue"),
            props: {
                insertRailStationsTableAsXML: true,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/pedestrian_map/:id",
            name: "pedestrian_map",
            component: () => import("../views/Toolbox/PedestrianIsochroneMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/cycling_map/:id",
            name: "cycling_map",
            component: () => import("../views/Toolbox/CyclingIsochroneMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/driving_map/:id",
            name: "driving_map",
            component: () => import("../views/Toolbox/DrivingIsochroneMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/amenities_map/:id",
            name: "amenities_map",
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: "Amenities Map",
                mapKey: "amenities_map",
                amenities: uk_amenities,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/osm_bus_map/:id",
            name: "osm_bus_map",
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: "Bus Map",
                mapKey: "osm_bus_map",
                amenities: osm_bus_amenities,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/local_transport_map/:id",
            name: "local_transport_map",
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: "Local Transport Facilities Map",
                mapKey: "local_transport_map",
                amenities: local_transport_amenities,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/us_amenities_map/:id",
            name: "us_amenities_map",
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: "Amenities Map",
                mapKey: "us_amenities_map",
                amenities: us_amenities,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/ev_charger_map/:id",
            name: "ev_charger_map",
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: "Electric Vehicle Charger Map",
                mapKey: "ev_charger_map",
                amenities: ev_charger,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/community_facilities_map/:id",
            name: "community_facilities_map",
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: "Community Facilities Map",
                mapKey: "community_facilities_map",
                amenities: community_facilities,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/collision_map/:id",
            name: "collision_map",
            component: () => import("../views/Toolbox/CollisionMap.vue"),
            meta: {
                auth: "authenticated",
            },
        },
        {
            path: "/sidepane/toolbox/ite_trip_gen/:id",
            name: "ite_trip_gen",
            component: () => import("../views/Word/SidepaneITETripGen.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/ite_oauth_callback",
            name: "ite_callback",
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            beforeEnter: async (to, from, next) => {
                const code = to.query.code;
                const state = to.query.state;
                // TODO error handling
                if (code && state) {
                    // You can handle the code and state here if needed
                    const response = await axios.get("/api/ite/callback", {
                        params: {
                            code: code,
                            state: state,
                        },
                    });
                    // If running in Office, send the ITE JWT to the parent (sidebar)
                    // Office JS - determine if app is being run in Office add-in or not
                    const info = await Office.onReady();
                    if (info.host && info.platform) {
                        await Office.context.ui.messageParent(true.toString());
                    }
                    // Store the ITE JWT in local storage
                    useStorage("ite_jwt", response.data);
                }
                // Should never get here - Word dialogbox should be closed
                next({
                    name: "ite_trip_gen",
                    params: {
                        id: 1,
                    },
                });
            },
        },
        {
            path: "/login",
            name: "login",
            component: () => import("../views/SchemeflowLogin.vue"),
            meta: {
                auth: "unauthenticated",
            },
        },
        {
            path: "/new",
            name: "new",
            component: () => import("../views/NewScheme.vue"),
            meta: {
                auth: "authenticated",
                checkTier: false,
            },
        },
        {
            path: "/portal",
            name: "portal",
            component: () => import("../views/SchemeflowPortal.vue"),
            meta: {
                auth: "authenticated",
                checkTier: false,
            },
        },
        {
            path: "/onboarding",
            name: "onboarding",
            component: () => import("../views/SchemeflowOnboarding.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/toolbox/policy/:id",
            name: "policy",
            component: () => import("../views/Toolbox/LocalPolicy.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
                featureFlag: "policyToolbox",
            },
        },
        {
            path: "/toolbox/parking_standards/:id",
            name: "parking_standards",
            component: () => import("../views/Toolbox/ParkingStandards.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
                featureFlag: "parkingStandards",
            },
        },
        {
            path: "/sidepane/toolbox/site_map/:id/:contentControlId",
            name: "cc_sidebar_site_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Site Location Map",
                toolbox: "site_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/amenities_map/:id/:contentControlId",
            name: "cc_sidebar_amenities_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Amenities Map",
                toolbox: "amenities_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/bus_services/:id/:contentControlId",
            name: "cc_sidebar_bus_services",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Bus Services",
                toolbox: "bus_services",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/rail_services/:id/:contentControlId",
            name: "cc_sidebar_rail_services",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Rail Services",
                toolbox: "rail_services_xml_table",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/pedestrian_map/:id/:contentControlId",
            name: "cc_sidebar_pedestrian_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Walking Isochrone",
                toolbox: "pedestrian_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/osm_bus_map/:id/:contentControlId",
            name: "cc_sidebar_osm_bus_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Bus Stops Map",
                toolbox: "osm_bus_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/local_transport_map/:id/:contentControlId",
            name: "cc_sidebar_local_transport_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Local Transport Facilities Map",
                toolbox: "local_transport_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/us_amenities_map/:id/:contentControlId",
            name: "cc_sidebar_us_amenities_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Amenities Map",
                toolbox: "us_amenities_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/ev_charger_map/:id/:contentControlId",
            name: "cc_sidebar_ev_charger_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Electric Vehicle Charger Map",
                toolbox: "ev_charger_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/community_facilities_map/:id/:contentControlId",
            name: "cc_sidebar_community_facilities_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Community Facilities Map",
                toolbox: "community_facilities_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/satellite_map/:id/:contentControlId",
            name: "cc_sidebar_satellite_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Satellite Map",
                toolbox: "satellite_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/cycling_map/:id/:contentControlId",
            name: "cc_sidebar_cycling_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Cycling Isochrone",
                toolbox: "cycling_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/highways_map/:id/:contentControlId",
            name: "cc_sidebar_highways_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Local Highways Map",
                toolbox: "highways_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/driving_map/:id/:contentControlId",
            name: "cc_sidebar_driving_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Driving Isochrone",
                toolbox: "driving_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/policy/:id/:contentControlId",
            name: "cc_sidebar_policy",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Local Policy Explorer",
                toolbox: "policy",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/parking_standards/:id/:contentControlId",
            name: "cc_sidebar_parking_standards",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Parking Standards",
                toolbox: "parking_standards",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/accident_map/:id/:contentControlId",
            name: "cc_sidebar_accident_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Collision Map",
                toolbox: "collision_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/template_tools",
            name: "word_sidebar_template_tools",
            component: () => import("../views/Word/SidepaneTemplateTools.vue"),
            props: true,
            meta: {
                auth: "authenticated",
                checkTier: true,
                featureFlag: "templateTools",
            },
        },
        {
            path: `/sidepane/summary/:key/:id/:contentControlId`,
            name: "cc_sidebar_summary",
            component: () => import("../views/Word/SidepaneSummaryEditorGenericSummary.vue"),
            props: true,
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/user_account/request_password_reset",
            name: "request_password_reset",
            component: () => import("../views/SchemeflowRequestPasswordReset.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/user_account/reset_password",
            name: "reset_password",
            component: () => import("../views/SchemeflowResetPassword.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/user_account/password_reset_required",
            name: "password_reset_required",
            component: () => import("../views/SchemeflowPasswordResetRequired.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/no_word_access",
            name: "no_word_access",
            component: () => import("../views/SchemeflowNoWordAccess.vue"),
            meta: {
                auth: "noWordAccess",
            },
        },
        {
            path: "/scout",
            name: "pdf_viewer",
            component: () => import("../views/Toolbox/TISPolicyExplorer.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/checkout/:price",
            name: "checkout",
            component: () => import("../views/SchemeflowCheckout.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/verify_payment",
            name: "verify_payment",
            component: () => import("../views/SchemeflowVerifyPayment.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/user_account/create_account",
            name: "create_account",
            component: () => import("../views/SchemeflowCreateAccount.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/tasks/:id/:task_id",
            name: "task",
            component: () => import("../views/ProjectTask.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/snippet_library/:project_id?",
            name: "snippet_library",
            component: () => import("../views/SnippetLibrary.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            props: (route) => ({ project_id: route.params.project_id ?? "" }),
        },
        {
            path: "/snippet_library/new_snippet/:snippet_type/:project_id?",
            name: "new_snippet",
            component: () => import("../views/NewSnippet.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            props: (route) => ({
                snippetType: route.params.snippet_type,
                projectId: route.params.project_id ?? "",
            }),
        },
        {
            path: "/snippet_library/edit_snippet/:snippet_id/:project_id?",
            name: "edit_snippet",
            component: () => import("../views/EditSnippet.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            props: (route) => ({
                snippetId: route.params.snippet_id,
                projectId: route.params.project_id,
            }),
        },
    ],
});

export default router;
