<template>
    <SchemeflowMap
        mapKey="site_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones']"
    ></SchemeflowMap>
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import { onMounted, onUnmounted } from "vue";
import SchemeflowMap from "@/components/SchemeflowMap.vue";

const store = useFormDataStore();

onMounted(async () => {
    setTimeout(() => {
        store.maps.site_map.getMap().once("rendercomplete", () => (store.mapsReadyForExport.site_map = true));
        store.maps.site_map.getMap().renderSync();
    }, 3000);
});

onUnmounted(() => {
    store.mapsReadyForExport.site_map = false;
});
</script>
