import { Control } from "ol/control.js";
import convert from "color-convert";

// Implements an openlayers control for legend
export default class SchemeflowLegend extends Control {
    constructor() {
        const container = document.createElement("div");
        container.classList.add("sf-legend");

        const titleContainer = document.createElement("div");
        titleContainer.classList.add("sf-legend-title-container");
        container.appendChild(titleContainer);

        // Add title
        const title = document.createElement("h3");
        title.classList.add("sf-legend-title");
        titleContainer.appendChild(title);

        super({
            element: container,
        });

        this.legendList = document.createElement("div");
        this.legendList.style.display = "grid";
        this.legendList.style.gridTemplateColumns = "1fr";
        this.legendList.style.rowGap = "10px";
        this.legendList.style.marginTop = "5px";
        this.legendList.style.marginBottom = "5px";

        this.title = title;

        container.appendChild(this.legendList);
    }

    clearLegend() {
        while (this.legendList.firstChild) {
            this.legendList.removeChild(this.legendList.firstChild);
        }
    }

    addItem(item) {
        this.legendList.appendChild(item.getElement());
    }

    setTitle(title) {
        if (title) {
            this.title.innerText = title;
            this.title.style.display = "block";
        } else {
            this.title.innerText = "";
            this.title.style.display = "none";
        }
    }
}

// Each legend item is made up of an icon and the legend item title (name),
// displayed as a single row on the legend. The icon may be one of the various
// types of legend icon defined below.
export class SFLegendItem {
    constructor({ title = "Item name", icon = new SFLegendIconRectangle() } = {}) {
        this.title = title;
        this.icon = icon;
    }

    getElement() {
        const item = document.createElement("div");
        item.style.display = "flex";
        item.style.alignItems = "center";

        const icon = document.createElement("div");
        icon.style.width = "25px";
        icon.style.margin = "0 5px 0 5px";
        icon.style.display = "flex";
        icon.style.justifyContent = "center";
        icon.style.alignItems = "center";

        icon.appendChild(this.icon.getElement());
        item.appendChild(icon);

        const title = document.createElement("div");
        title.innerText = this.title;
        title.style.margin = "0 5px 0 5px";
        title.style.lineHeight = "1.0";
        item.appendChild(title);

        return item;
    }
}

// Legend icon which is an SVG image, displayed without modification. If more
// complex processing is required (e.g. overlayiung on a background), use
// SFLegendIconElement.
export class SFLegendIconImage {
    constructor({ src = null, width = "16", height = "12" } = {}) {
        this.src = src;
        this.width = width;
        this.height = height;
    }

    getElement() {
        const img = document.createElement("img");
        img.src = this.src;
        img.width = this.width;
        img.height = this.height;
        img.style.display = "block";
        img.style.marginLeft = "auto";
        img.style.marginRight = "auto";

        return img;
    }
}

// Legend icon which is displayed as a rectangle, generally used for polygon
// shapes on map, such as site boundary and isochrones
export class SFLegendIconRectangle {
    constructor({
        width = "20px",
        height = "12px",
        fillColor = "#FF7276",
        fillOpacity = "1.0",
        outlineColor = "#B4B4B4",
        outlineStyle = "solid",
        outlineWidth = 2,
    } = {}) {
        this.width = width;
        this.height = height;
        this.fillColor = fillColor;
        this.fillOpacity = fillOpacity;
        this.outlineColor = outlineColor;
        this.outlineStyle = outlineStyle;
        this.outlineWidth = outlineWidth;
    }

    getElement() {
        const div = document.createElement("div");
        div.style.width = this.width;
        div.style.height = this.height;

        // Get background colour and opacity
        const bgColVals = convert.hex.rgb(this.fillColor);
        bgColVals.push(this.fillOpacity);
        let bgCol = "rgba(" + bgColVals.join(", ") + ")";
        div.style.backgroundColor = bgCol;

        div.style.outlineColor = this.outlineColor;
        div.style.outlineStyle = this.outlineStyle;
        div.style.outlineWidth = this.outlineWidth + "px";

        div.style.display = "block";
        div.style.marginLeft = "auto";
        div.style.marginRight = "auto";

        return div;
    }
}

// Legend icon displayed as circle, genereally used for point positions, such as
// amenity locations
export class SFLegendIconCircle {
    constructor({
        diameter = "10px",
        fillColor = "#FF7276",
        fillOpacity = "1.0",
        outlineColor = "#B4B4B4",
        outlineStyle = "solid",
        outlineWidth = "1",
    } = {}) {
        this.diameter = diameter;
        this.fillColor = fillColor;
        this.fillOpacity = fillOpacity;
        this.outlineColor = outlineColor;
        this.outlineStyle = outlineStyle;
        this.outlineWidth = outlineWidth;
    }

    getElement() {
        const div = document.createElement("div");
        div.style.borderRadius = "9999px";
        div.style.width = this.diameter;
        div.style.height = this.diameter;
        div.style.backgroundColor = this.fillColor;
        div.style.opacity = this.fillOpacity;
        div.style.outlineColor = this.outlineColor;
        div.style.outlineStyle = this.outlineStyle;
        div.style.outlineWidth = this.outlineWidth + "px";

        div.style.display = "block";
        div.style.marginLeft = "auto";
        div.style.marginRight = "auto";

        return div;
    }
}

// Legend icon displayed as a line, such as used for road marking
export class SFLegendIconLine {
    constructor({
        length = "24px",
        width = "7px",
        fillColor = "#FF7276",
        fillOpacity = "1.0",
        outlineColor = "#8F8F8F",
        outlineStyle = "solid",
        outlineWidth = "1px",
    } = {}) {
        this.length = length;
        this.width = width;
        this.fillColor = fillColor;
        this.fillOpacity = fillOpacity;
        this.outlineColor = outlineColor;
        this.outlineStyle = outlineStyle;
        this.outlineWidth = outlineWidth;
    }

    getElement() {
        const div = document.createElement("div");
        div.style.width = this.length;
        div.style.height = this.width;
        div.style.backgroundColor = this.fillColor;
        div.style.opacity = this.fillOpacity;
        div.style.borderTopColor = this.outlineColor;
        div.style.borderBottomColor = this.outlineColor;
        div.style.borderTopStyle = this.outlineStyle;
        div.style.borderBottomStyle = this.outlineStyle;
        div.style.borderTopWidth = this.outlineWidth;
        div.style.borderBottomWidth = this.outlineWidth;

        div.style.display = "block";
        div.style.marginLeft = "auto";
        div.style.marginRight = "auto";

        return div;
    }
}

// Legend icon containing an arbitary HTML element to be used as icon.
export class SFLegendIconElement {
    constructor(element) {
        this.elem = element;
    }

    getElement() {
        return this.elem;
    }
}

export class SFLegendIconFromConfig {
    constructor({ img = "", width = 18, height = 18, bg = false } = {}) {
        this.img = img;
        this.width = width;
        this.height = height;
        this.bg = bg;
    }

    getElement() {
        const div = document.createElement("div");

        if (this.bg) {
            div.style.borderRadius = "9999px";
            div.style.width = this.width + 4 + "px";
            div.style.height = this.height + 4 + "px";
            div.style.backgroundColor = this.bg.color;
        } else {
            div.width = this.width;
            div.height = this.height;
        }

        div.style.margin = "auto";

        div.style.display = "flex";
        div.style.justifyContent = "center";
        div.style.alignItems = "center";

        const img = document.createElement("img");
        img.src = this.img;
        img.width = this.width;
        img.height = this.height;
        img.style.marginLeft = "auto";
        img.style.marginRight = "auto";
        div.appendChild(img);

        return div;
    }
}
